import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        name: 'Home',
        components: {
            default: () => import('@/views/pc/index'),
            pc: () => import('@/views/pc/index'),
            m: () => import('@/views/move/index'),
        },
    }, {
        path: '/news_:id',
        name: 'News',
        components: {
            default: () => import('@/views/pc/informationData'),
            pc: () => import('@/views/pc/informationData'),
            m: () => import('@/views/move/informationData'),
        },
    },
  {
    path: '/demo',
    name: 'demo',
    components: {
      default: () => import('@/views/pc/demo'),
      pc: () => import('@/views/pc/demo'),
      m: () => import('@/views/pc/demo'),
    },
  }

]

const router = new VueRouter({
    routes,
    mode: 'history'   //去除#号
})

export default router
